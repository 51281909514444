@import 'variables.css';

.propiedadCarousel {
    position: absolute;
    right:0px;
    border-radius: 10px 0px 0px 10px !important;
    font-size:30px !important;
    top:5px;
    z-index: 1000 !important;
}

.propiedadCarousel2 {
  position: absolute;
  right:0px;
  border-radius: 10px 0px 0px 10px !important;
  font-size:16px !important;
  top:5px;
  z-index: 1000 !important;
}

    .tamano {
    width: 100%;
    height: 200px;
    object-fit: cover;
    cursor:pointer;
  }


  .tamano2{
    width: 100%;
    height: 60%;
    object-fit: cover;
    cursor:pointer;
  }


  .propiedadCarouselRef {
    position: absolute;
    right:0px;
    border-radius: 10px 0px 0px 10px !important;
    font-size:22px !important;
    top: 90%;
    background-color:var(--secondary);
}

.propiedadCarouselRef2 {
  position: absolute;
  right:0px;
  border-radius: 10px 0px 0px 10px !important;
  font-size:13px !important;
  top: 85%;
  background-color:var(--secondary);
}

.propiedadesCarouselZoom {
  position: absolute;
  left:0% !important;
  font-size:13px !important;
  bottom: 0%;
  background-color:var(--background) !important;
  z-index: 999;
  padding-left: 1px !important;
  padding-right: 1px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
