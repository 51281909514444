@import 'variables.css';

.navBar {
    background-color: var(--primary);
    box-shadow: 3px 3px 3px 1px rgba(7, 7, 7, 0.7) !important;
}

.navBarLink {
  font-size: 22px;
  padding-right: 50px;
  color: var(--texto) !important;
  margin-right: 20px;
}

.navBarLink:hover {
  color: var(--accent-color) !important;
}

.celColor {
  color: var(--accent-color) !important;
}

.celColor:hover {
  color: white !important;
}


.nav-link.active {
  color: var(--secondary) !important; 
}




