@import 'variables.css';

.cardContacto2 {
    margin: 10px;
    border:none !important;
    box-shadow: 5px 10px 20px 1px rgba(7, 7, 7, 0.7) !important;
    background: var(--background);
    color: var(--text-dark);
}



.cardContacto-body {
    background: var(--background-opacity3) !important;
}
.cardContacto1-body {
    background: var(--background-opacity3) !important;
}
.cardContacto2-body {
    background: var(--background-opacity3) !important;
}
.cardContacto3-body {
    background: var(--background-opacity3) !important;
}


.cardContacto2:hover {
    box-shadow: 5px 10px 20px 1px rgba(255,255,255,0.50) !important;
    transition: all 0.6s linear;
    color: white;
}

.cardContacto1-body:hover {
    color:  #25D366 !important;
}

.cardContacto2-body:hover {
    color:  #D58936 !important;
}

.cardContacto3-body:hover {
    color:  #23395B !important;
}

