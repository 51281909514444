.btn-whatsapp{
    position: fixed;
    width: 55px;
    height: 55px;
    line-height: 55px;
    bottom: 55px;
    right: 30px;
    background: #0df053;
    color: var(--texto);
    border-radius: 50px;
    text-align: center;
    box-shadow: 0pc 1px 10px rgba(0,0,0,0.3);
    z-index: 100;
    
}

.btn-whatsapp:hover{
  text-decoration: none;
  color: #0df053;
  background: white;
}

@media (max-device-width: 576px) {
  .btn-whatsapp{
    position: fixed;
    width: 55px;
    height: 55px;
    line-height: 55px;
    bottom: 45px;
    right: 5px;
    background: #0df053;
    color: var(--texto);
    border-radius: 50px;
    text-align: center;
    box-shadow: 0pc 1px 10px rgba(0,0,0,0.3);
    z-index: 1000;
    
}
}