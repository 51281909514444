@import 'variables.css';

.filter {
    padding-top: 8%;
    padding-bottom: 0;
    text-transform: uppercase;
}

#filter {
  display: block;
  padding-bottom: 4%;
  margin-top: 5%;
  margin-left : 5%;
  margin-right : 5%;
  margin-bottom: 0;
}
#imageNone {
  display: block;
  padding-bottom: 4%;
  margin-top: 5%;
  margin-left : 5%;
  margin-right : 5%;
  margin-bottom: 0;
}

#imageNone2 {
  display: block;
  padding-bottom: 0%;
  margin-top: 0%;
  margin-left : 0%;
  margin-right : 0%;
  margin-bottom: 0;
}

.nombreFooter {
  justify-content:start !important;
  padding-left: 7%;
}

.iconosFooter {
  justify-content:end !important;
  padding-right: 12%;
}

.nombrePortada {
  display: block !important;
  justify-content:start !important;
  padding-left: 7%;
}


@media (min-device-width: 820px) and (max-device-width: 900px) and (orientation: landscape){
  #contacto {
    display: none;
  }
  .padding{
    padding-top: 0px !important;
  }
  .widht {
    width: 100% !important;
  }
  .widthTable {
    width: 40% !important;
  }
  .withTd {
    width: 70% !important;
  }
  .withTdValue {
    width: 30% !important;
  }

.paddingMobile {
  padding-top: 2px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.paddingMap {
  padding-bottom: 25px !important;
}

#imageNone {
  display: none;
}

#imageNone2 {
  display: none;
}

.footer-portada {
  background-color: var(--primary);
  color: var(--texto);
  height:50px;
  line-height:50px;
  text-align:center;
  position:relative !important;
  bottom:0;        
  left:0;
  width:100%; 
  --bs-gutter-x: 0px !important;
}

.containerPadding{
  padding-left: 0% !important;
  padding-right: 0% !important;
}

}

@media (min-device-width: 576px) {
  #margenPaddingFilter {
    margin-left: 15%;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-left: 2;
    padding-right: 0;

  }

  .containerPadding{
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
}

@media (max-device-width: 667px) { 
  #margenPaddingFilter {
    margin-top: 5%;
    margin-left: 4%;
    margin-right: 5%;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  .colContacto {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .paddingTexto {
    padding-top: 70px !important;
    margin-top: 30px !important;
  }

  .buttonW {
    padding-bottom: 30px !important;
  }
  #filter {
    display: none;
  }
  #imageNone {
    display: none;
  }
  #imageNone2 {
    display: none;
  }

  .nombreFooter {
    justify-content:center !important;
    padding-left: 0px !important;

  }
  
  .iconosFooter {
    justify-content:center !important;
    padding-right: 0px !important;
  }
  .footer-general {
    height:100px !important;
    line-height:50px !important;
  }

  .nombrePortada {
    display: none !important;
  }

  .footer-portada {
    background-color: var(--primary);
    color: var(--texto);
    height:50px;
    line-height:50px;
    text-align:center;
    position:relative !important;
    bottom:0;        
    left:0;
    width:100vw; 
    --bs-gutter-x: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .portadaG {
    --bs-gutter-x: 0px !important;
    --bs-gutter-y: 0px !important;
    min-height: 100vh !important;
  }

  .containerPadding {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .paddingId {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }
  #contacto {
    display: none;
  }
  .padding{
    padding-top: 0px !important;
  }
  .widht {
    width: 100% !important;
  }
  .withTd {
    width: 70% !important;
  }
  .withTdValue {
    width: 30% !important;
  }
  .paddingMobile {
    padding-top: 2px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  
  .paddingMap {
    padding-bottom: 25px !important;
  }
  
}

@media  (max-device-width: 667px) and (orientation: landscape){
  .paddingFiltro {
    padding-left: 60px !important;
    padding-bottom: 15px !important;
  }

  .buttonMargin {
    margin-right: 10px !important;
  }

  .contLogo {
    position: relative !important;

  }

  .footer-portada {
    background-color: var(--primary);
    color: var(--texto);
    height:50px;
    line-height:50px;
    text-align:center;
    position:relative !important;
    bottom:0;        
    left:0;
    width:100%; 
    --bs-gutter-x: 0px !important;
  }
  .portadaG {
    --bs-gutter-x: 0px !important;
    --bs-gutter-y: 0px !important;
    min-height: 100vh !important;
  }

  .containerPadding {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .paddingId {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }

  .paddingTexto {
    padding-top: 0px !important;
    margin-top: 0px !important;
    padding-bottom: 50px !important;
  }

  #contacto {
    display: none;
  }
  .padding{
    padding-top: 0px !important;
  }
  .widht {
    width: 100% !important;
  }
  .widthTable {
    width: 40% !important;
  }
  .withTd {
    width: 70% !important;
  }
  .withTdValue {
    width: 30% !important;
  }

.paddingMobile {
  padding-top: 2px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.paddingMap {
  padding-bottom: 25px !important;
}
#filter {
  display: none;
}
#imageNone {
  display: none;
}
#imageNone2 {
  display: none;
}

.colContacto {
  padding-right: 0px !important;
  width: 70% !important;
  padding-top: 35px;
  padding-bottom: 35px;
}

.colComunicacion {
  padding-right: 0px !important;
  width: 60% !important;
  
}

}

@media  (max-device-width: 740px) and (orientation: landscape){
  /* .footer-portada {
    background-color: var(--primary);
    color: var(--texto);
    height:50px;
    line-height:50px;
    text-align:center;
    position:relative !important;
    bottom:0;        
    left:0;
    width:100%; 
    --bs-gutter-x: 0px !important;
  } */
  .nombrePortada{
    display: none !important;
  }
  .footer-portada {
    background-color: var(--primary);
    color: var(--texto);
    height:50px;
    line-height:50px;
    text-align:center;
    position:relative !important;
    bottom:0;        
    left:0;
    width:100%; 
    --bs-gutter-x: 0px !important;
  }
  .portadaG {
    --bs-gutter-x: 0px !important;
    --bs-gutter-y: 0px !important;
  }

  .containerPadding {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .paddingId {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }

  .colContacto {
    padding-right: 0px !important;
    width: 70% !important;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .colComunicacion {
    padding-right: 0px !important;
    width: 60% !important;
    
  }
}

/* Medidas para iPad Air 1 */
@media (min-device-width: 1000px) and (max-device-width: 1200px)  and (orientation: landscape) {
  .paddingFiltro {
    padding-right: 0px !important;
    width: 40% !important;
  }

  .paddingPropiedades {
    padding-right: 0px !important;
    width: 60% !important;
  }

  .colPropiedad{
    padding-right: 0px !important;
    width: 70% !important;
  }

  .colContacto {
    padding-right: 0px !important;
    width: 60% !important;
    padding-bottom: 35px;

  }

  .colComunicacion {
    padding-right: 0px !important;
    width: 40% !important;
  }

  .email {
    width: 20% !important;
  }

  .cel {
    width: 20% !important;
  }

  .buttonApt {
    margin-right: 30px !important;
  }

  #contacto {
    display: none;
  }
  .padding{
    padding-top: 0px !important;
  }
  .widht {
    width: 100% !important;
  }
  .widthTable {
    width: 40% !important;
  }
  .withTd {
    width: 70% !important;
  }
  .withTdValue {
    width: 30% !important;
  }

.paddingMobile {
  padding-top: 2px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.paddingMap {
  padding-bottom: 25px !important;
}

#ipad {
  display: block !important;
  /* padding-left: 5%; */
  padding-left: 4%;
  padding-right: 4%;
}

#imageNone {
  display: none;
}

#imageNone2 {
  display: none;
}

.containerPadding{
  padding-left: 0% !important;
  padding-right: 0% !important;
}

}



/* Medidas para iPad Air 2 */
@media (min-device-width: 820px) and (max-device-width: 900px) {
  .paddingFiltro {
    padding-right: 0px !important;
    width: 40% !important;
  }

  .paddingPropiedades {
    padding-right: 0px !important;
    width: 60% !important;
  }

  .colPropiedad{
    padding-right: 0px !important;
    width: 100% !important;
  }

  .colContacto {
    padding-right: 0px !important;
    width: 70% !important;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .colComunicacion {
    padding-right: 0px !important;
    width: 60% !important;
    
  }

  .contLogo {
    position: relative !important;
    width: 100% !important;
  }

  .email {
    width: 20% !important;
  }

  .cel {
    width: 20% !important;
  }
  

  .containerPadding {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .paddingId {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }

 .paddingTexto {
    padding-top: 0px !important;
    margin-top: 0px !important;
    padding-bottom: 50px !important;
  }

  .buttonW {
    width: 100% !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    padding-bottom: 25px !important;
  }

  .buttonApt {
    margin-right: 30px !important;
  }

  #contacto {
    display: none;
  }
  .padding{
    padding-top: 0px !important;
  }
  .widht {
    width: 100% !important;
  }
  .widthTable {
    width: 40% !important;
  }
  .withTd {
    width: 70% !important;
  }
  .withTdValue {
    width: 30% !important;
  }

.paddingMobile {
  padding-top: 2px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.paddingMap {
  padding-bottom: 25px !important;
}

#imageNone {
  display: none;
}

#imageNone2 {
  display: none;
}

#ipad {
  display: block !important;
  /* padding-left: 5%; */
  padding-left: 4%;
  padding-right: 4%;
}

.nombreFooter {
  justify-content:start !important;
  padding-left: 7% !important;
}

.iconosFooter {
  justify-content:end !important;
  padding-right: 12% !important;
}

.portadaG {
  --bs-gutter-x: 0px !important;
  --bs-gutter-y: 0px !important;
}

}

/* Medidas para iPad Mini */
@media (min-device-width: 700px) and (max-device-width: 800px) {
  .paddingFiltro {
    padding-right: 0px !important;
    width: 40% !important;
  }

  .paddingPropiedades {
    padding-right: 0px !important;
    width: 60% !important;
  }

  .colPropiedad{
    padding-right: 0px !important;
    width: 100% !important;
  }

  .colContacto {
    padding-right: 0px !important;
    width: 70% !important;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .colComunicacion {
    padding-right: 0px !important;
    width: 60% !important;
  }

  .contLogo {
    position: relative !important;
    width: 100% !important;
  }

  .containerPadding {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .paddingId {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }

 .paddingTexto {
    padding-top: 0px !important;
    margin-top: 0px !important;
    padding-bottom: 50px !important;
  }

  .buttonW {
    width: 100% !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    padding-bottom: 25px !important;
  }

  .buttonApt {
    margin-right: 30px !important;
  }

  .email {
    width: 20% !important;
  }

  .cel {
    width: 20% !important;
  }

  #contacto {
    display: none;
  }
  .padding{
    padding-top: 0px !important;
  }
  .widht {
    width: 100% !important;
  }
  .widthTable {
    width: 40% !important;
  }
  .withTd {
    width: 70% !important;
  }
  .withTdValue {
    width: 30% !important;
  }

.paddingMobile {
  padding-top: 2px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.paddingMap {
  padding-bottom: 25px !important;
}

#imageNone {
  display: none;
}

#imageNone2 {
  display: none;
}

#ipad {
  display: block !important;
  /* padding-left: 5%; */
  padding-left: 4%;
  padding-right: 4%;
}

.portadaG {
  --bs-gutter-x: 0px !important;
  --bs-gutter-y: 0px !important;
}
}

@media (min-device-width: 900px) and (max-device-width: 1000px)  and (orientation: landscape) {

  .colContacto {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .contLogo {
    position: relative !important;
    width: 100% !important;
  }

  .footer-portada {
    background-color: var(--primary);
    color: var(--texto);
    height:50px;
    line-height:50px;
    text-align:center;
    position:relative !important;
    bottom:0;        
    left:0;
    width:100%; 
    --bs-gutter-x: 0px !important;
  }
  .portadaG {
    --bs-gutter-x: 0px !important;
    --bs-gutter-y: 0px !important;
  }

  .containerPadding {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .paddingId {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }

 .paddingTexto {
    padding-top: 0px !important;
    margin-top: 0px !important;
    padding-bottom: 50px !important;
  }

  .buttonW {
    width: 100% !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    padding-bottom: 25px !important;
  }

  .email {
    width: 20% !important;
  }

  .cel {
    width: 20% !important;
  }

  #contacto {
    display: none;
  }
  .padding{
    padding-top: 0px !important;
  }
  .widht {
    width: 100% !important;
  }
  .widthTable {
    width: 40% !important;
  }
  .withTd {
    width: 70% !important;
  }
  .withTdValue {
    width: 30% !important;
  }

.paddingMobile {
  padding-top: 2px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.paddingMap {
  padding-bottom: 25px !important;
}


}

@media (min-device-width: 912px) and (max-device-width: 920px){
  .buttonApt {
    margin-right: 40px !important;
  }

  #contacto {
    display: none;
  }
  .padding{
    padding-top: 0px !important;
  }
  .widht {
    width: 100% !important;
  }
  .widthTable {
    width: 40% !important;
  }
  .withTd {
    width: 70% !important;
  }
  .withTdValue {
    width: 30% !important;
  }

.paddingMobile {
  padding-top: 2px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.paddingMap {
  padding-bottom: 25px !important;
}

.nombreFooter {
  justify-content:center !important;
  padding-left: 0px !important;
}

.iconosFooter {
  justify-content:center !important;
  padding-right: 0px !important;
}

.containerPadding{
  padding-left: 0% !important;
  padding-right: 0% !important;
}

}
@media (min-device-width: 1368px) and (max-device-width: 1380px){
  .buttonApt {
    margin-right: 40px !important;
  }
  .email {
    width: 20% !important;
  }

  .cel {
    width: 20% !important;
  }

  #contacto {
    display: none;
  }
  .padding{
    padding-top: 0px !important;
  }
  .widht {
    width: 100% !important;
  }
  .widthTable {
    width: 40% !important;
  }
  .withTd {
    width: 70% !important;
  }
  .withTdValue {
    width: 30% !important;
  }

.paddingMobile {
  padding-top: 2px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.paddingMap {
  padding-bottom: 25px !important;
}

.containerPadding{
  padding-left: 0% !important;
  padding-right: 0% !important;
}

}

@media (min-device-width: 1380px) and (max-device-width: 1747px){

  .widthTable {
    width: 22% !important;
  }
  .widthRow {
    width: 100% !important;
  }
  .withTd {
    width: 70% !important;
  } 

  .containerPadding{
    padding-left: 0% !important;
    padding-right: 0% !important;
  }

}




.options {
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.ColGral{
    padding-left: 3%;
    padding-right: 3%;
    background-color: var(--secondary-opacity);
    padding-bottom: 10%;
    border-radius: 7px;
    z-index: 100;
    box-shadow: 5px 10px 20px 1px rgba(7, 7, 7, 0.7) !important; 

}

.active {
  background-color: var(--primary) !important;
}

input[type='radio']:checked {
      background-color: var(--primary);
      border: solid var(--primary);
      box-shadow: 0px 0px 0px 0px !important;
}
input[type='radio'] {
    box-shadow: 0px 0px 0px 0px !important;
}


.form-switch .form-check-input {
    height: 20px;
    width: 65px;
}
.form-switch .form-check-input:focus {
    border-color: var(--primary);
    outline: 0;
    box-shadow: 0 0 0 0 var(--primary);
}
.form-switch .form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
    border: none;
}

input[type='text'] {
    box-shadow: 0px 0px 0px 0px !important;
    border-color: var(--primary);
    color: var(--primary);
}

input[type='text']:focus {
    box-shadow: 0px 0px 0px 0px !important;
    border-color: var(--primary);
    color: var(--primary);
}

select,
option {
  color: var(--primary) !important;
  box-shadow: 0px 0px 0px 0px !important;
  border-color: var(--primary) !important;
}

select,
option:focus {
  color: var(--primary) !important;
  box-shadow: 0px 0px 0px 0px !important;
  border-color: var(--primary);
}

  option:checked {
    background-color: var(--primary) !important;
    color: white !important;  
  }

  .btn {
    background-color: var(--primary) !important;
    color: var(--texto) !important;
    border: solid var(--primary) !important;
  }


  .btn:focus {
    box-shadow: 1px 1px 1px 1px #302F2E !important;
  }

  .btn:hover {
    background-color: var(--accent-color) !important;
    box-shadow: 1px 1px 1px 1px var(--accent-color) !important;
    color: white !important;
    border: solid var(--accent-color) !important;
  }

  .select-box .option:hover {
    background: #414b57 !important;
  }

  .background-filter {
    background-color: var(--background);
    padding-top: 35px;
  }
  
  .general{
    min-height: calc(100vh - 136px);
  }