:root {
    --primary: #23395B; /* Azul menu botones*/
    --secondary: rgb(109,127,139); 
    --background: rgb(208,205,215);
    --secondary-opacity: rgba(109,127,139,0.5); /* fondo card and filter*/
    --texto: white;
    --texto-dark: #00202A;
    --primary-shadow: rgba(35,57,91,0.7);
    --accent-color: #D58936;
    --background-opacity: rgb(208,205,215, 0.2);

    --background-opacity3: rgb(208,205,215, 0.8);
}