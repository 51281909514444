@import 'variables.css';

  .banner {
    min-height:  100vh;
    background: linear-gradient(to top,transparent,rgb(35,57,91)),url("../img/ImagenPortada.jpg") no-repeat center center fixed;
    background-size: cover;
  }

  .banner2 {
    min-height: calc(100vh - 136px);
    background: linear-gradient(to top,transparent,rgb(35,57,91)),url("../img/ImagenPortada.jpg") no-repeat center center fixed;
    background-size: cover;
    padding-right: 0% !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .logo {
    width: 100%;
    height: auto;
  }

  .contLogo {
    position: absolute;
  }



  .propiedad {
      color: var(--accent-color);
  }

  .titulo {
    color: var(--texto);
  }

  .card-portada {
      opacity: 0.9;
  }

  .button-portada {
    background-color: var(--accent-color) !important;
    border: solid var(--accent-color) !important;
    box-shadow: 5px 10px 20px 1px rgba(7, 7, 7, 0.7) !important;
  }

  .button-portada:hover {
    background-color: var(--primary) !important;
    border: solid var(--primary) !important;
    box-shadow: 5px 10px 20px 1px rgba(255,255,255,0.50) !important;
    transition: all 0.6s linear;
  }

  .centrador{
    text-align: center;
  }

  .centrarDerecha{
    text-align: right;
  }


  