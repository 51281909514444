@import 'variables.css';

.fondoModal{
  background-color: rgba(0, 0, 0, 0.70);
}

.fotosModel{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  background-color: rgba(0, 0, 0, 0.70);
}

.modalBody{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  border: none !important;
  width: max-content !important;
  background-color: rgba(0, 0, 0, 0.70);
}

.modal.show .modal-dialog {
  justify-content: center !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.fotosModel .modal-content{
  align-items: center !important;
  background-color: rgba(0, 0, 0, 0.70) !important;
}

.back {
  background-color: rgba(0, 0, 0, 0.70);
}

/* Para dejar imagen del tamano 16:9 */
.img1609 {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}
.img1609 img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%,-50%);
}

.botonPaginacion {
    background-color: var(--primary);
    color: var(--texto);
}

.bg-colorBadge {
    background-color: var(--primary);
    color: var(--texto) !important;
  }

.backgroundFondo {
    background-color: var(--background);
    
}


.notFound {
    text-align: center;
}


.btn-check:checked + label {
    background-color: var(--accent-color) !important;
}

@media (max-device-width: 576px) {
    .padding-buttons {
      padding-top: 20px;
    }
    .paddingMobile {
      padding-top: 1% !important;
    }
    .paddingFiltro {
      padding-bottom: 5% !important;
    }
}




