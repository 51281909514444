@import 'variables.css';


.commentInput {
    box-shadow: 0px 0px 0px 0px !important;
    border-color: var(--primary) !important;
    color: var(--primary) !important;
}

.commentInput:focus {
    box-shadow: 0px 0px 0px 0px !important;
    border-color: var(--primary) !important;
    color: var(--primary) !important;
}


.rowContacto {
      position: fixed;
}

.paddingContacto {
    padding-right : 3% !important;
    padding-left: 1% !important;
    padding-top: 3% !important;
}


