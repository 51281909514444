@import 'variables.css';

.footer {
    background-color: var(--primary);
    color: var(--texto);
  }

  .footer-portada {
    background-color: var(--primary);
    color: var(--texto);
    line-height:50px;
    text-align:center;
    position:absolute;
    bottom:0;        
    left:0;
    width:100%;

  }

  .footer-general {
    background-color: var(--primary);
    color: var(--texto);
    height:50px;
    line-height:50px;
    text-align:center;
    position: static !important;
    bottom: 0 !important;              
    left:0;
    width:100% !important;
    padding-left: 0% !important;
    padding-right: 0% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }

  @import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');
  .WillFooter {
    font-family: 'Alice', serif;
    font-size: x-large;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap');
  .NegociosFooter {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: smaller;
  }