@import 'variables.css';

.card:hover {
    box-shadow: 5px 10px 20px 1px rgba(255,255,255,0.50) !important;
    transition: all 0.6s linear;
}

.zoom {
    position: absolute;
  }

@media (min-device-width: 576px) {
    .zoom:hover {
        transform: scale(1.4);
        z-index: 1;
    }
}

@media (max-device-width: 576px) {
    .zoom:hover {
        transform: scale(1.2);
        z-index: 1;
    }
}

.card-body{
    background: var(--secondary-opacity);
    color: var(--text-dark);
}

.card {
    margin: 10px;
    border:none !important;
    box-shadow: 5px 10px 20px 1px rgba(7, 7, 7, 0.7) !important;
    
}

.item {
    position:relative;
    display:inline-block;
}

.badge {
    position: absolute;
    right:0px;
    border-radius: 10px 0px 0px 10px !important;
    font-size:15px !important;
    top:5px;
}


